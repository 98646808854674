import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import Hero from '../../components/home/Hero';
import What from '../../components/home/What';
import Why from '../../components/home/Why';
import Signup from '../../components/home/Signup';
import How from '../../components/home/How';
import FAQ from '../../components/home/FAQ';
import LoginModal from '../../components/forms/LoginModal';
import { segmentTrack } from '../../includes/analytics';
import { StaticImage } from 'gatsby-plugin-image';

export type IndexProps = {
  data: IndexQueryData
};
export default function Index({ data }:IndexProps) {
  segmentTrack('dasher_application_page');

  return <Layout>
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <meta name="description" content={data.site.siteMetadata.description} />
      <title>{data.site.siteMetadata.name}</title>
    </Helmet>
    <LoginModal />
    <Hero
      headline={<><div className="title-row">Inside the loop.</div> <div className="title-row">Outside the loop.</div> <div className="title-row">You decide.</div></>}
      subHeadline={<>Earn and deliver when you want</>}
      img={<StaticImage className="img" src="../../images/hero/6757f9a01c6a4137aa5b9c7c8cc7918f.jpg" alt="" layout="fullWidth" placeholder="none" />}
    />
    <What />
    <Why />
    <Signup />
    <How />
    <FAQ />
  </Layout>;
}

type IndexQueryData = {
  site: {
    siteMetadata: {
      name: string,
      description: string,
    }
  }
};
export const query = graphql`
  query {
    site {
      siteMetadata {
        name
        description
      }
    }
  }
`;
